<template>
<div>
    <!-- <a href="javascript:void(0)"><span class="dot-count bg-warning"></span><i class="feather-bell font-xl text-current"></i>
        <div class="menu-dropdown">
            <h4 class="fw-700 font-xs mb-4">Notification</h4>
            <div>
                <div class="card bg-transparent-card w-100 border-0 pl-5 mb-3" v-for="i in 3" :key="i">
                    <div class="w40 position-absolute left-0 rounded-circle"><i class="fas fa-clock font-xl text-current"></i></div>
                    <h5 class="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Pending <span class="text-grey-400 font-xsssss fw-600 float-right mt-1"> 3 min</span></h5>
                    <h6 class="text-grey-500 fw-500 font-xssss lh-4">Fashionate with Mun's</h6>
                </div>
                <hr>
                <div class="card bg-transparent-card w-100 border-0">
                    <router-link class="font-xsss text-current text-center m-auto fw-700 d-block" to="/notification">See more</router-link>
                </div>
            </div>
        </div>
    </a> -->
    <router-link to="/notification"><span class="dot-count bg-warning"></span><i class="feather-bell font-xl text-current"></i></router-link>
</div>
</template>
<script>
export default {
    name: 'Notification',
    data(){
        return{

        }
    },
    methods:{

    }
}
</script>

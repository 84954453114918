<template>
    <div>
        <div class="main-wrap">

        <!-- navigation -->
        <nav class="navigation scroll-bar" v-bind:class="{'nav-active': isNavbarActive, 'menu-active': isMenuActive}">
            <div class="container pl-0 pr-0">
                <div class="nav-content">
                    <div class="nav-top">
                        <router-link to="/browse"><div class="display1-size mr-2 ml-4 mb-1"><img :src="'/images/logo-1-new.png'" width="40"></div><span class="d-inline-block fredoka-font ls-3 fw-600 text-current-eling font-xl logo-text mb-0">Elingway </span> </router-link>
                        <a href="#" class="close-nav d-inline-block d-lg-none" @click="toggleNavbar()"><i class="ti-close bg-grey mb-4 btn-round-sm font-xssss fw-700 text-dark ml-auto mr-2 "></i></a>
                    </div>
                    <div class="nav-caption fw-600 font-xssss text-grey-500"><span></span>Menu</div>
                    <ul class="mb-3">
                        <li class="logo d-none d-xl-block d-lg-block"></li>
                        <li><router-link to="/get-started" class="nav-content-bttn open-font" :class="{'active' : isActiveMenu('get-started')}"><i class="feather-help-circle mr-3"></i><span>Getting Started</span></router-link></li>
                        <li><router-link to="/" class="nav-content-bttn open-font" :class="{'active' : isActiveMenu('')}"><i class="feather-tv mr-3"></i><span>All Courses</span></router-link></li>
                        <li><router-link to="/my-course" class="nav-content-bttn open-font" :class="{'active' : isActiveMenu('my-course')}"><i class="feather-bookmark mr-3"></i><span>My Course</span></router-link></li>
                        <li><router-link to="/calendar" class="nav-content-bttn open-font" :class="{'active' : isActiveMenu('calendar')}"><i class="feather-calendar mr-3"></i><span>Calendar</span></router-link></li>
                        <li><router-link to="/meet-tutors" class="nav-content-bttn open-font" :class="{'active' : isActiveMenu('meet-tutors')}"><i class="feather-users mr-3"></i><span>E-Space</span></router-link></li>
                        <li><router-link to="/toefl" class="nav-content-bttn open-font" :class="{'active' : isActiveMenu('toefl')}"><i class="feather-file-text mr-3"></i><span>Test Programs</span></router-link></li>
                        <li><router-link to="/class-registration" class="nav-content-bttn open-font" :class="{'active' : isActiveMenu('class-registration')}"><i class="feather-sunrise mr-3"></i><span>Class Registration</span></router-link></li>
                    </ul>
                </div>
            </div>
        </nav>
        <!-- main content -->
        <div class="main-content min-vh-100" v-bind:class="{'menu-active': isMenuActive}">
            <div class="middle-sidebar-header bg-white position-sticky">
                <button class="header-menu" @click="toggleNavbar()"></button>
                <div action="#" class="float-left header-search">
                    <div class="form-group mb-0 icon-input">
                        <i class="feather-menu font-lg text-grey-400 mt-0" @click="toggleMenu()"></i>
                        <input type="text" disabled  class="bg-transparent border-0 lh-32 pt-2 pb-2 pl-5 pr-3 font-xsss fw-500 rounded-xl w350">
                    </div>
                </div>
                <ul class="d-flex ml-auto right-menu-icon">
                    <li>
                        <notification/>
                    </li>
                    <li><router-link to="/message"><span class="dot-count bg-warning"></span><i class="feather-message-square font-xl text-current"></i></router-link></li>
                    <li>
                        <a href="javascript:void(0)" @click="toggleSidebar()" class="btn-toggle-sidebar"><img :src="user.photo" alt="..." class="w40 rounded-circle mt--1 btn-toggle-sidebar"></a>
                    </li>
                    <li></li>
                </ul>
            </div>
            <div class="middle-sidebar-bottom">
                <div class="middle-sidebar-left">
                    <router-view></router-view>
                </div>
                <right-sidebar :personal-event="personalEvent" :is-sidebar-active="isSidebarActive" @close-sidebar="isSidebarActive = false"/>
            </div>
        </div>
        <!-- main content -->
        <app-footer @button-sidebar-clicked="toggleSidebar" :user="user"/>

        <div class="app-header-search">
            <form class="search-form">
                <div class="form-group searchbox mb-0 border-0 p-1">
                    <input type="text" class="form-control border-0" placeholder="Search...">
                    <i class="fas fa-search">
                        <notification/>
                    </i>
                    <a href="#" class="ml-1 mt-1 d-inline-block close searchbox-close">
                        <i class="ti-close font-xs"></i>
                    </a>
                </div>
            </form>
        </div>

        </div>

    </div>
</template>

<script>
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import RightSidebar from '../helpers/RightSidebar.vue'
import AppFooter from '../helpers/AppFooter.vue'
import Notification from '../helpers/Notification.vue'

export default ({
    name: 'CoreLayout',
    components: {
        RightSidebar,
        AppFooter,
        Notification
    },
    data(){
        return {
            media : process.env.VUE_APP_URL_CLOUD,
            user: {
                photo: 'https://elingway-storage.s3.ap-southeast-1.amazonaws.com/default/profile.png'
            },
            isSidebarActive: false,
            isNavbarActive: false,
            isMenuActive: false,
            fullpath: this.$route.path,
            options: {
                rewind : true,
                perPage : 3,
                autoplay : true,
                arrows : false,
                pagination: false,
                gap: '1rem'
            },
            personalEvent: 0,
        }
    },
    created() {
        this.getUser()
    },
    methods: {
        async getUser(){
            this.user = JSON.parse(localStorage.getItem('user'))
        },
        toggleSidebar(){
            this.isSidebarActive = !this.isSidebarActive
        },
        toggleNavbar(){
            this.isNavbarActive = !this.isNavbarActive
        },
        toggleMenu(){
            this.isMenuActive = !this.isMenuActive
        },
        isActiveMenu(path){
            var fullPath = this.fullpath.split("/")[1]
            if(fullPath === path){
                return true
            } else {
                return false
            }
        }
    }
})
</script>
